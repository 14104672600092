/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, Action,State} from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
import { CoopPartner } from '@/components/pages/cooperation/partners/cooperation-partners.interface'
const namespace: string = 'customer';

export default class CooperationPartnersPage extends Vue {
	@State('customer') customer: CustomerState | undefined;
	@Action('cooperationPartner', {namespace}) cooperationPartner: any;

	partner: CoopPartner = {
		name: "",
		email: "",
		phone: "",
		description: ""
	}

	onSubmit() {
		this.cooperationPartner(this.partner);
		this.partner = {
			name: "",
			email: "",
			phone: "",
			description: ""
		}
	}
}
